import Vue from "vue";
import axios from "axios";

const selectCidades: HTMLSelectElement | null = document.querySelector('.dropdown');

async function getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition( async (position) => {
            console.log("API de geolocalização chamada com sucesso.");

            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            // Chama a função para usar a Geocoding API
            const city = await getCityFromCoordinates(latitude, longitude);
            return city
        }, showError);
    } else {
        console.log("Geolocalização não é suportada neste navegador.");
    }

}

async function getCityFromCoordinates(lat, lng) {
    const apiKeyElement = document.querySelector('#google-api-key') as HTMLInputElement | null;
    const apiKey = apiKeyElement ? apiKeyElement.value : null;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

    try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === 'OK') {
            const results = data.results;
            const addressComponents = results[0].address_components;

            const cityComponent = addressComponents.find(component => component.types.includes("administrative_area_level_2"));
            if (cityComponent) {
                const city = cityComponent.long_name;
                if (await verifyCity(city)) {
                    const formatedCity = formatText(city);
                    localStorage.setItem('cidade', formatedCity);
                    window.location.href = formatedCity
                }

            } else {
                console.log("Cidade não encontrada no array de componentes de endereço.");
            }

        } else {
            console.log('Geocoding API falhou: ', data.status);
        }
    } catch (error) {
        console.log("Erro ao chamar Geocoding API: ", error);
    }
}

async function showError(error) {
    switch(error.code) {
        case error.PERMISSION_DENIED:
            console.log("Usuário negou a solicitação de geolocalização.");
            break;
        case error.POSITION_UNAVAILABLE:
            console.log("Informação de localização não está disponível.");
            break;
        case error.TIMEOUT:
            console.log("A solicitação para obter a localização expirou.");
            break;
        case error.UNKNOWN_ERROR:
            console.log("Ocorreu um erro desconhecido.");
            break;
    }
}

async function verifyCity(city): Promise<boolean> {
    const formattedCity = formatText(city);
    try {
        const response = await axios.get("/api/cidades-do-grupo");
        const cidadesDoGrupo = response.data.cidadesDoGrupo.map((cidade: any) => formatText(cidade.titulo));
        return cidadesDoGrupo.includes(formattedCity);
    } catch (error) {
        console.error("Erro ao verificar cidade:", error);
        return false;
    }
}

function formatText(text: string): string {
    const textoSemEstado = text.split(" - ")[0];
    const textoSemAcentos = textoSemEstado.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const textoFormatado = textoSemAcentos.replace(/\s+/g, '-').toLowerCase();
    return textoFormatado;
}

function getCityFromURL() {
    const expression: RegExp = /(^\/[\w\-]+\/?)/i;
    const match = expression.exec(window.location.pathname);
    if (match) {
        return match[0].replaceAll('/', '');
    }
    return null;
}

if (selectCidades) {
    new Vue({
        el: selectCidades,
        data: {
            cidade: '',
            oldCidade: '',
            manualChange: localStorage.getItem('manualChange') === 'true',
        },
        methods: {
            onChangeHandle: function (slug) {
                this.manualChange = true;
                localStorage.setItem('manualChange', 'true');
                const cidadeAtual = this.filterCidadeAtual();
                localStorage.setItem('cidade', slug);
                window.location.href = window.location.href.replace(cidadeAtual, slug);
            },
            filterCidadeAtual: function () {
                return getCityFromURL();
            },

        },
        mounted() {
            const storedCity = localStorage.getItem('cidade');
            const cityFromURL = getCityFromURL();
            if (cityFromURL) {
                this.cidade = cityFromURL;
                if (selectCidades) {
                    selectCidades.value = this.cidade;
                }
            } else {
                window.location.href = '/sao-luis';
            }
            if (storedCity) {
                this.cidade = formatText(storedCity);
                this.oldCidade = this.cidade;
                selectCidades.value = this.cidade;
                const cidadeAtual = this.filterCidadeAtual();
                if (cidadeAtual !== this.cidade) {
                    window.location.href = window.location.href.replace(cidadeAtual, this.cidade);
                }
            } else {
                getLocation();
            }

            // Lógica para dropdown
            document.querySelectorAll('.dropdown').forEach((dropdown) => {
                dropdown.addEventListener('click', function(event) {
                    event.stopPropagation();
                    const nextElement = this.querySelector('.dropdown__menu');
                    if (nextElement) {
                        nextElement.classList.toggle('show');
                    }
                });
            });

            document.querySelectorAll('.dropdown__submenu > p').forEach((submenu) => {
                submenu.addEventListener('mouseover', function(e) {
                    e.preventDefault();
                    // e.stopPropagation();
                    document.querySelectorAll('.dropdown__submenu .dropdown__menu').forEach((menu) => {
                        menu.classList.remove('show');
                    });
                    const nextElement = (this as HTMLElement).nextElementSibling;
                    if (nextElement) {
                        nextElement.classList.add('show');
                    }
                });
            });

            window.addEventListener('click', function(e) {
                const target = e.target as Element;
                if (target && !target.matches('.dropdown__toggle') && !target.matches('.dropdown__submenu')) {
                    document.querySelectorAll('.dropdown__menu').forEach(function(menu) {
                        menu.classList.remove('show');
                    });
                }
            });
        }
    });
}
